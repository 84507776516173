@media (min-width:1200px) {
    @import url('./navbar.css');
}
@media (max-width:1200px) {

* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0px;
    font-family: 'segoe ui';
  }
  
  .nav {
    height: 50px;
    width: 100%;
    background-color: #4d4d4d;
    position: relative;
    z-index: 10;
  }
  
  .nav > .nav-header {
    display: inline;
  }
  
  .nav > .nav-header > .nav-title {
    /* display: inline-block; */
    font-size: 15px;
    color: #fff;
    padding: 10px 10px 10px 10px;
  }
  .nav > .nav-header > .nav-title img {
    margin-right: 10px !important;
  }
  .nav > .nav-btn {
    display: none;
  }
  
  .nav > .nav-links {
    display: inline;
    float: right;
    font-size: 14px;
  }
  
  .nav > .nav-links > a {
    display: inline-block;
    padding: 5px 5px 5px 5px;
    text-decoration: none;
    color: #efefef;
  }
  
  .nav > .nav-links > a:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .nav > #nav-check {
    display: none;
  }
  
  @media (max-width:850px) {
   
    .nav > .nav-btn {
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .nav > .nav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 13px;
    }
    .nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .nav > .nav-btn > label > span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 2px solid #eee;
    }
    .nav > .nav-links {
      position: absolute;
      display: block;
      width: 100%;
      background-color: #333;
      height: 0px;
      transition: all 0.3s ease-in;
      overflow-y: hidden;
      top: 50px;
      left: 0px;
    }
    .nav > .nav-links > a {
      display: block;
      width: 100%;
    }
    .nav > #nav-check:not(:checked) ~ .nav-links {
      height: 0px;
    }
    .nav > #nav-check:not(:checked) ~ .toggler {
      display: none;
    }
    .nav > #nav-check:checked ~ .nav-links {
      height: calc(45vh);
      min-height: fit-content;
      overflow-y: auto;
    }
    
    .reg{
        background-color: #665700;
        box-sizing: border-box;
        border-radius: 38px;
        margin: 5px;
        padding: 5px 0px !important;
        /* padding-left: 3px !important;
        padding-right: 3px !important; */
        width: 45% !important;
        margin: auto;
        margin-top: 5px;
    }
    .toggler{
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        /* background-color: rgba(0, 0, 0, 1); */
        opacity: 1;
    }

   @media (min-width:700px){
    .nav > .nav-links {
      display: inline;
      float: right;
      font-size: 18px;
    }
    .nav > #nav-check:checked ~ .nav-links {
      height: calc(43vh);
      min-height: fit-content;
      overflow-y: auto;
    }
   }

    
  }
}