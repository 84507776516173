@media(min-width: 600px){.events-container {
    position: relative;
    background-color: black;
    top: 0vh;
    width: 100%;
    height: 150vh;
    overflow: hidden;
    cursor: default;
}

/*.events-back {*/
/* .vertical{
    position: absolute;
    font-size: 5vh;
    font-family: rubik;
    font-weight: 900;
    writing-mode: vertical-rl;
    text-shadow: 2px 0 transparent, -2px 0 #ffffff, 0 2px #ffffff, 0 -2px #ffffff,
             1px 1px #ffffff, -1px -1px #ffffff, 1px -1px #ffffff, -1px 1px #ffffff;
    top: 3vh;
    left: -3.77vw;
} */



.img {
    width: 100%;
    display: block;
    margin: auto;
}

.content1 {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    background: rgba(34, 30, 30, 0.7);
    justify-content: center;

    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
    color: #b0abab;


}

.content1:hover {
    opacity: 1;
}

.content1>* {
    transform: translateY(25px);
    transition: transform 0.6s;
}

.content1:hover>* {
    transform: translateY(0px);

}

.events-back {
    width: 100%;
    height: 100%;
    /* position: fixed; */
    /* top: 150vh; */
    /* z-index: 5; */
}

.events-container img {
    width: 100%;
    height: 100%;
}

.events-line1 {
    position: absolute;
    top: 6vh;
    font-size: 2.8vh;
    font-family: rubik;
    color: white;
    left: 16vw;
    writing-mode: vertical-rl;
}

.events-heading {
    position: absolute;
    top: 22vh;
    left: 1vw;
    font-size: 25vh;
    font-weight: 100;
    writing-mode: vertical-rl;
    -webkit-text-stroke-width: 2px;

    font-family: 'Bowlby One SC', cursive;

    word-wrap: break-word;
    color: white;
    /* text-shadow: 2px 0 transparent, -2px 0 #ffffff, 0 2px #ffffff, 0 -2px #ffffff, */
    /* 1px 1px #ffffff, -1px -1px #ffffff, 1px -1px #ffffff, -1px 1px #ffffff;    */
    letter-spacing: 15px;
    text-decoration: solid;
    -webkit-text-fill-color: transparent;


}






.events-valo {
    position: absolute;
    top: 14vh;
    left: 22vw;
    /* height: 35%; */
    height: 380px;
    width: 44%;
    /* color: white; */
    font-family: 'Bowlby One SC', cursive;
}

.events-valo-img {
    height: 100%;
}

.events-garena {
    position: absolute;
    top: 75vh;
    left: 22vw;
    /* height: 35%; */
    height: 380px;
    font-family: 'Bowlby One SC', cursive;
    width: 44%;
}

.events-bgmi {
    position: absolute;
    top: 14vh;
    right: 0;
    /* height: 75.5%; */
    height: 780px;
    font-family: 'Bowlby One SC', cursive;
    width: 20%;
}

/* 
 { */
.vertical {
    position: absolute;
    font-size: 5vh;

    font-weight: 100;
    /* font-size: 16vh; */
    /* font-weight: 900; */
    left: -12vw;
    position: absolute;

    font-family: rubik;
    font-weight: 900;
    writing-mode: vertical-rl;
    /* text-shadow: 2px 0 transparent, -2px 0 #ffffff, 0 2px #ffffff, 0 -2px #ffffff, */
    /* 1px 1px #ffffff, -1px -1px #ffffff, 1px -1px #ffffff, -1px 1px #ffffff; */
    top: 3vh;
    left: -3.5vw;
    text-decoration: solid;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;


    -webkit-text-stroke: 1px white;
}

/* .events-bgmi p {
    font-size: 16vh;
    font-weight: 900;
    left: -12vw;
} */

.events-line2 {}

.events-line2 {
    display: flex;
    position: absolute;
    left: 18vw;
    bottom: 10vh;
    display: flex;
    justify-content: space-around;
}

.events-line2-text {
    font-size: 2.7vh;
    color: white;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
}

.events-follow {
    width: 10%;
    padding: 1%;


}

.events-follow {
    width: 15%;
    justify-content: center;
    background-color: grey;
    border-radius: 10px;
    padding-bottom: 0%;
}

.sidebgm {
    font-size: normal;
}

.hero {

    margin: 0px 100px;
    align-items: center;
}

.hero2 {

    margin: 0px 40px;
    align-items: center;
}

.content2 {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    background: rgba(34, 30, 30, 0.7);
    justify-content: center;

    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
    color: #b0abab;


}

.content2:hover {
    opacity: 1;
}

.content2>* {
    transform: translateY(25px);
    transition: transform 0.6s;
}

.content2:hover>* {
    transform: translateY(0px);

}

.content3 {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    background: rgba(34, 30, 30, 0.7);
    justify-content: center;

    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
    color: #b0abab;


}

.content3:hover {
    opacity: 1;
}

.content3>* {
    transform: translateY(25px);
    transition: transform 0.6s;
}

.content3:hover>* {
    transform: translateY(0px);

}

.rounded-full {
    color: #b0abab;
    align-items: center;
    background-color: #797979;
    font-weight: 750;
    font-size: 18px;
    width: 130px;
    height: 36px;
    border-radius: 12px;
    border: 1px solid black;
    margin-left: 10px;
}

.rounded-full:hover {
    background-color: #3b3535;
}
}
@media(max-width: 600px){
    .events-container{
        height: 1000px;
        position: relative;
        top: -10vh;
    }
    .events-content{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        /* margin: 0 auto; */
        height: 100%;
    }
    .events-heading{
        writing-mode: horizontal-tb;
        font-size: 3.5rem;
        font-weight: 700;
        /* color: beige; */
    }
    .events-line1{
        writing-mode: horizontal-tb;
        color: rgb(239, 233, 233);

    }
    .events-back{
        position: absolute;
        left: 0;
        width: 100vw;
        height: 100%;
    }
    .event-parent{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .image{
        display: flex;
        flex-direction: column;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        height: 250px;  
        height: 100%;
        position: relative;      
    }
    .event-sm{
        width: 90vw;
    }
    .hero-text{
        color: white;
        width: 80%;
    }
    .content{
        position: absolute;
        display: none;
    }
    .events-bgmi , .events-garena , .events-valo{
        height: 200px;
        margin: 20px 0;
    }
    .vertical{
        writing-mode: vertical-rl;
        position: absolute;
        z-index: 10;
        top: 2px;
        left: -18px;
        color: rgb(223, 212, 212);
        font-size: 25px;
        font-weight: 600;
        
    }
    .events-line2-text{
        font-weight: 600;
        padding: 10px 0px 0px 0px;

    }
    .follow-button{
        margin: 15px 0px;
        width: 100px;
        border-radius: 8px;
        height: 27px;
        font-weight: 600;
        background-color: rgb(229, 229, 217);
    }
}