#webgi-canvas-container {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  pointer-events: none;
  min-height: 831px;
  background: transparent;
  z-index: 2;
  overflow: hidden;
}

#webgi-canvas {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
}

.scroll-down-cont {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* right: 2vw; */
  bottom: 4vh;
  z-index: 100;
  color: white;
}

.scroll-down-cont h1{
  font-size: 17px;
  margin-bottom: 0px;
}
.font-awesome-arrow{
  font-size: 20px;
  animation: moveUpDown 2s linear infinite;
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
}