@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&family=Roboto:ital,wght@0,300;0,400;1,100&display=swap');

.nav-container{
    font-family: 'Roboto', sans-serif;
    position: fixed;
    background-color:#181818;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 8vh;
    color: white;
    z-index: 10;
    width: 100vw;
    overflow: hidden;
    opacity: 1;
    top: 0px;
}

.nav-container-root{
    font-family: 'Roboto', sans-serif;
    position: fixed;
    background-color: #181818;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 8vh;
    color: white;
    z-index: 10;
    width: 100vw;
    overflow: hidden;
    opacity: 0;
    top: -50px;
}
.nav-title{
    margin-left: 2%;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 750;
    font-size: larger;
}
.nav-title img{
    height: 100%;
    margin-right: 10%;
}
.nav-title span{
    color: red;
}
.nav-facility{
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 550;
}

.nav-facility *{
    color: white;
}
.nav-register{
    border-radius: 20px;
    padding: 0px 10px;
    border: 2px solid black;
    background-color: #665700;
    color: black!important;
   width: 140px; 
   height: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
    text-decoration: none;
} 
.nav-register:hover{
    background-color: #181818;
    transition: 0.5s;
}

.nav-register a p{
    margin: 0;
}

.nav-home{
    margin:2%;
} 
.nav-about{
    margin:2%;
} 
.nav-sponsors{
    margin: 2%;
}
.nav-team{
    margin:2%;
} 
.line{
    text-decoration: none;
}



/* For Small Screen */


