@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@1,100&family=Plus+Jakarta+Sans&display=swap');
* {
    /* font-family: plus, sans-serif; */
    font-family: 'Plus Jakarta Sans', sans-serif;
    /* background-color: black; */
  }
  
  .text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 25px;
  }
  body{
    background-color: black;
  }
  
  .responsive-cell-block {
    min-height: 75px;
  }
  
  .responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    justify-content: space-evenly;
  }
  
  .outer-container {
    padding-top: 10px;
    padding-right: 50px;
    padding-bottom: 10px;
    padding-left: 50px;
    background-color: rgb(0, 0, 0);
  }
  
  .inner-container {
    max-width: 1320px;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
  }
  
  .section-head-text {
    margin-top: 35px;
    margin-right: 0px;
    margin-bottom: 25px;
    margin-left: 0px;
    font-size: 45px;
    font-weight: 900;
    line-height: 48px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #C34343;
  }
  
  .section-subhead-text {
    font-size: 25px;
    color: rgb(153, 153, 153);
    line-height: 35px;
    /* max-width: 470px; */
    text-align: center;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
  }
  
  .inner {
    position: relative;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    padding: 1rem;
    box-shadow: inset 0 0 0 2px red;
}
  
  .img-wrapper {
    width: 100%;
  }
  
  .team-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    box-shadow: inset 0 0 0 1px rgb(123, 123, 123);
  }
  
  .social-media-links {
    /* width: 125px; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .name {
    font-size: 20px;
    font-weight: 700;
    color: rgb(102, 102, 102);
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: 2px;
    margin-left: 0px;
  }
  
  .position {
    font-size: 20px;
    font-weight: 700;
    color: rgb(0, 135, 177);
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
  }
  
  .team-img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border-bottom-left-radius:0px ;
    border-bottom-right-radius:0px ;
  }
  
  .team-card-container {
    width: 280px;
    margin: 0 0 40px 0;
  }
  .details{
    width: 100%;
  }
  .social-media-links svg{
    width: 20px;
    padding: 15px;
    color: white;
  }
  /* .zoom1{
    height: 378px;
   } */
  .zoom2{
    object-fit: cover;
  }
  
  @media (max-width: 500px) {
    .outer-container {
      padding: 10px 20px 10px 20px;
    }
  
    .section-head-text {
      text-align: center;
    }
  }
