@media(min-width: 600px){
    .past-container1{
        padding: 10px;
        margin-bottom: 5vh;
    }
    .past-container{
        display: flex;
        width: 100%;
         position: relative;
         /* top: 6vh; */
        height: 100vh;
        /* overflow: hidden;  */
    }
    /* .past-container img{
        height: 100%;
        width: 100%;
    } */
    .past-img{
        transition: 0.3s ease-out;
    }
    .past-img:hover{
        transform: translateY(-10px) scale(1.05);
    }
    
    .past-body-column1{
        margin-top: 20vh;
        width: 50vw;
        position: relative;
    
    }
    .past-body-column2{
        width: 25vw;
        position: relative;
    }
    .past-body-column3{
        width: 25vw;
    }
    .past-body-column2-1{
        margin-top: vw;
        height: 38vw;
    }
    .past-body-column2-2{
        height: 38vw;
    }
    .past-body-column3-1{
        height: 38vw;
    }
    .past-body-column3-2{
        height: 38vw;
    }
    .image2{
        margin-top: 12vh;
        width: 22vw;
    
    
    }
    .image1{
        margin-top:-8vh;
        width:40vw;
    }
    .image3{
        margin-top: -20vh;
        width: 18vw;
    
    
    }
    .past-heading{
        margin: 0;
        font-size: 40px;
        color: white;
    }
    .past-container-mob{
        display: none;
    }
    
}
@media(max-width: 600px){
    .past-container1{
        /* position: relative; */
        /* top: -10vh; */
    }
    .past-container{
        /* display: flex; */
        /* flex-direction: column; */
        display: none;
    }
    .past-container-mob{
        color: white;
    }
    .past-img{
        width: 80%;
    }
    
}
