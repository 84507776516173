@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&display=swap');
.header-container{
    position: relative;
    /* top: 6vh; */
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.header-back{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: -50vw;
    /* position: fixed; */
}
.header-back img{
    position: absolute;
    width: 100%;
    height: 100%;
    /* position: fixed; */
}
.header-film{
    width: 100%;
    z-index: -10;
    height: 100vh;
    background-color: rgb(32, 30, 30);
    position: absolute;
    top: 0;
}
.header-why{
    z-index: 2;
    position: absolute;
    top: 10.2%;
    height: 13%;
    /* font-family: rubik; */
    font-family: 'Bowlby One SC', cursive;
    color: transparent;
    /* -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px white; */
    font-size: 9vw;
    font-weight: 300;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #665700;
    /* add some text shadow so that fill is transparent and only shadow appears for text */
    left: 0%;

    
}
.header-player{
    z-index: 1;
    position: absolute;
    top: 12vh;
    left: 10vw;
    height: 88vh;
}
.header-player img{
    height: 100%;
}
.header-esports{
    position: absolute;
    z-index: 0;
    height: 13.45%;
    top: 10%;
    left: 24vw;
    /* font-family: rubik; */
    font-family: 'Bowlby One SC', cursive;
    font-size: 9vw;
    font-weight: 300;
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #ffffff;
}
    
.header-text{
    z-index: 1;
    position: absolute;
    right: 0;
    top: 37%;
    width: 42%;
}
.header-text-image{
    width: 100%;
}
.header-text-image img{
    width: 100%;
}
.header-text-written{
    z-index: 1;
    color: rgb(211, 188, 188);
    position: absolute;
    padding: 9%;
    top: 0;
    font-size: 1.4vw;
    text-align: left;
}

@media(max-width: 600px){
    .header-back{
        height: 62%;
        width: 300vw;
        left: -190vw;
        z-index: 1;
        overflow: hidden;
    }
    .header-why{
        top: 2%;
        font-size: 13vw;
        left: 40vw;
        color: #8a7c2e;
    }
    .header-esports{
        top: 8%;
        left: 40vw;
    }
    .header-player{
        height: 50vh;
        left: 0vw;
    }
    .header-text{
        z-index: 60;
        top: 62%;
        width: 100%;
        height: 50vh;
    }
    .header-text-written{
        font-size: 1.7vh;
        padding: 7%;
        }
        

}

