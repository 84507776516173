.foot-container{
    position: relative;
    background-color: black;
    /* top: 6vh; */
    height: 60vh;
    overflow: hidden;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.foot-upper{
    position: absolute;
    height: 22vh;
    width: 100vw;
    top: 4%;
}
.foot-gun{
    height: 80%;
    z-index: 10;
    position: absolute;
    right: 10px;
    top: 0;
}
.foot-upline{
    position: absolute;
    right: 28%;
    top: 1.4vh;
    width: 70vw;
}
.foot-social{
    /* background-color: white; */
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 15vh;
    /* left: 7vw; */
    width: 70vw;
    /* height: 10vh; */
    /* color: #513D3D; */
    color: rgb(219, 199, 199);
}
.foot-social *{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 20%;
    /* background-color: white; */
}

.foot-social img{
    width: 50px;
}

.foot-social img:hover{
    scale: 1.1;
    transition: 1s;
    cursor: pointer;
}

.foot-social p{
    cursor: pointer;
}

.foot-social p{
    font-weight: 700;
}

.foot-follow-us{
    position: absolute;
    top: 0vh;
    color: rgb(236, 16, 16);
    width: 10%;
    height: 10vh;
    left: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: rgb(255, 255, 255); */
    align-items: first baseline;
}
.foot-follow{
    height: 5vh;
    /* background-color: aqua; */
    font-size: 4vh;
    font-weight: 700;
}
.foot-us{
    font-size: 4vh;
}
.foot-pages{
    position: absolute;
    top: 30vh;
    width: 60%;
    /* background-color: white; */
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.foot-pages-title{
    color: rgb(227, 15, 15);
    font-size: 4vh;
    font-weight: 700;
}
.foot-home , .foot-about , .foot-spon , .foot-team{
    display: flex;
    align-items: center;
    width: 15%;
    font-weight: 700;
    color: #d2a6a6;
    text-decoration: none;
}
.foot-pages img{
    width: 17%;
    margin: 0px 5px;
}
.foot-lower{
    position: absolute;
    height: 25vh;
    top: 35vh;
    /* bottom: 0vh; */
    /* background-color: white; */
}
.foot-downline{
    position: absolute;
    top: 5vh;
    right: -90vw;
}
.foot-logo{
    width: 8vw;
    /* width: 100%; */
    position: absolute;
    /* right: -2vw; */
    left: 89vw;
    top: 0vh;
}
.foot-logo-name{
    position: absolute;
    color: red;
    /* background-color: white; */
    font-size: 4.5vh;
    font-weight: 600;
    top: -1.5vh;
    /* right: 80vw; */
    left: 78vw;
}
.foot-logo-year{
    position: absolute;
    color: white;
    top: 7vh;
    width: 10vw;
    /* border: 3px solid white; */
    font-size: 4.5vh;
    font-weight: 700;
    left: 80vw;
}
.foot-logo-year span{
    color: rgb(195, 74, 74);
}
.foot-copy{
    position: absolute;
    color: white;
    width: 40vw;
    /* background-color: aquamarine; */
    top: 12vh;
}

@media(max-width: 600px){
    .foot-container{
        height: 62vh;
    }
    .foot-gun{
        height: 50px;
        top: 0.8vh;
    }
    .foot-follow-us{
        height: 7vh;
    }
    .foot-follow , .foot-us{
        font-size: 3vh;
    }
    .foot-social{
        left: 5%;
        top: 12vh;
        flex-direction: column;
    }
    .foot-social *{
        width: 50%;
    }
    .foot-social img{
        width: 40px;
    }
    .foot-pages{
        top: 13vh;
        left: 40%;
        flex-direction: column;
        /* background-color: white; */
    }
    .foot-pages-title{
        font-size: 3vh;
    }
    .foot-pages *{
        width: 50%;
    }
    .foot-logo{
        top: 8vh;
        width: 60px;
        left: 81vw;
    }
    .foot-logo-name{
        font-size: 2.5vh;
        top: 10vh;
        left: 55vw;
    }
    .foot-logo-year{
        top: 14vh;
        font-size: 3vh;
        left: 70vw;
    }
    .foot-downline{
        display: none;
    }
    .foot-copy{
        font-size: xx-small;
    }
}

